export const left = [
  {
    text: 'Find friends',
    img: 'friends',
    link: '/friends',
  },
  {
    text: 'Saved posts',
    img: 'saved',
    link: '/saved',
  },
  {
    text: 'Following',
    img: 'events',
    link: '/',
  },
  {
    text: 'Chat',
    img: 'messenger',
    link: '/',
  },
];
