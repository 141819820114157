// @packages
import PropagateLoader from 'react-spinners/PropagateLoader';
// @scripts
import './ActivateForm.css';

export default function ActivateForm({ type, header, text, loading }) {
  return (
    <div className="blur">
      <div className="popup">
        <div
          className={`popup_header ${
            type === 'success' ? 'success_text' : 'error_text'
          }`}
        >
          {header}
        </div>
        <div className="popup_message">{text}</div>
        <PropagateLoader color="#0cb1c7"size={20} loading={loading} />
      </div>
    </div>
  );
}
