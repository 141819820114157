import React from 'react'

export default function RoosterLogo({color}) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="300.000000pt" height="300.000000pt" viewBox="0 0 300.000000 300.000000"
    preserveAspectRatio="xMidYMid meet">
   <metadata>
   Created by potrace 1.10, written by Peter Selinger 2001-2011
   </metadata>
   <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
   fill={color} stroke="none">
   <path d="M1030 2312 c0 -20 9 -55 19 -77 18 -39 86 -115 103 -115 4 0 8 -4 8
   -9 0 -19 -118 -35 -252 -36 -76 0 -138 -3 -138 -8 0 -4 28 -35 63 -70 42 -42
   87 -75 139 -100 l78 -39 -30 -24 c-77 -65 -192 -261 -206 -350 -8 -52 18 -84
   69 -84 39 0 62 21 73 65 15 65 86 184 144 241 114 115 228 165 389 172 125 5
   189 -7 291 -55 93 -44 209 -157 258 -250 l32 -61 -232 -4 -233 -3 -67 -32
   c-83 -39 -154 -108 -194 -188 -26 -54 -29 -70 -29 -157 0 -91 2 -99 30 -140
   104 -147 364 -135 523 24 71 71 110 151 125 261 l11 87 83 0 c141 0 168 29
   132 145 -12 39 -34 96 -51 128 l-29 58 26 31 c43 51 58 90 58 148 0 90 -44
   160 -202 322 -73 76 -138 138 -142 138 -15 0 -10 -49 7 -83 9 -18 36 -60 60
   -93 24 -34 51 -78 59 -97 17 -41 20 -127 5 -127 -5 0 -10 8 -10 18 0 36 -62
   142 -105 180 -99 87 -200 117 -458 137 -197 15 -281 30 -359 64 l-48 20 0 -37z
   m820 -1000 c0 -77 -27 -140 -84 -198 -59 -58 -121 -84 -204 -84 -46 0 -58 4
   -83 29 -49 49 -34 139 35 218 60 68 100 83 229 83 l107 0 0 -48z"/>
   <path d="M1174 1740 c-41 -16 -44 -50 -44 -539 0 -455 4 -525 32 -543 21 -12
   72 -9 94 6 18 14 19 32 22 475 l3 461 307 0 307 1 -40 36 c-50 44 -143 89
   -214 103 -64 12 -437 12 -467 0z"/>
   </g>
   </svg>
  )
}
